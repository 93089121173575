import { decryptValue, encryptValue } from './crypto';

const securedSessionStorage = {
  setItem: (key: string, value: string|null): void => {
    const _value = value || '';

    const encryptedValue = encryptValue(_value);
    sessionStorage.setItem(key, encryptedValue);
  },
  getItem: (key: string): string | null => {
    const value = sessionStorage.getItem(key);
    return value ? decryptValue(value as string) : null;
  },
  removeItem: (key: string): void => {
    sessionStorage.removeItem(key);
  },
  clear: (): void => {
    sessionStorage.clear();
  },
};

export default securedSessionStorage;
