import { Amplify, Auth } from 'aws-amplify';
import { isNull } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';
import * as queryString from 'querystring';
import Background from './assets/images/background.png';
import './assets/styles/App.css';
import PyyplLoader from './components/PyyplLoader';
import PyyplLogo from './components/PyyplLogo';
import securedSessionStorage from './lib/securedSessionStorage';
import ChatBot, { ChatBotProps } from './views/ChatBot/ChatBot';
// import SignUp from './views/Auth/SignUp';
import Verify from './views/Kyc/Verify';
import Welcome from './views/Kyc/Welcome';

const { REACT_APP_PYYPL_WEB_APP_VERSION: PYYPL_WEB_APP_VERSION } = process.env;

const {
  REACT_APP_PYYPL_API_DOMAIN: PYYPL_API_DOMAIN,
  REACT_APP_PYYPL_AWS_IDENTITY_POOL_ID: PYYPL_AWS_IDENTITY_POOL_ID,
  REACT_APP_PYYPL_AWS_REGION: PYYPL_AWS_REGION,
  REACT_APP_PYYPL_WEB_APP_USER_POOL_ID: PYYPL_WEB_APP_USER_POOL_ID,
  REACT_APP_PYYPL_WEB_APP_USER_CLIENT_ID: PYYPL_WEB_APP_USER_CLIENT_ID,
} = process.env;

Amplify.configure({
  Analytics: {
    disabled: true,
  },
  Auth: {
    userPoolWebClientId: PYYPL_WEB_APP_USER_CLIENT_ID,
    userPoolId: PYYPL_WEB_APP_USER_POOL_ID,
    identityPoolId: PYYPL_AWS_IDENTITY_POOL_ID,
    region: PYYPL_AWS_REGION,
    storage: securedSessionStorage,
  },
  aws_appsync_graphqlEndpoint: `https://${PYYPL_API_DOMAIN}/graphql`,
  aws_appsync_region: PYYPL_AWS_REGION,
  authenticationFlowType: 'CUSTOM_AUTH',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PrivateRoute = ({
  component: Component,
  ...rest
}: RouteProps<string> & {
  component: (props: any) => React.ReactElement<any>;
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const initAuth = async () => {
      Auth.currentAuthenticatedUser()
        .then((user) => setIsAuthenticated(!!user))
        .catch(() => setIsAuthenticated(false));
    };
    initAuth();
  }, []);

  if (isNull(isAuthenticated)) return <PyyplLoader loading />;
  return (
    <Route
      {...rest}
      render={(props) =>
        // @ts-ignore
        (isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        ))}
    />
  );
};

const App = () => {
  const navHeaderRef = useRef(null);

  const parseChatBotRouteQueryParams = () => {
    try {
      const qs = queryString.parse(window.location.search);
      const { '?userData': userData } = qs || {};
      return JSON.parse(userData as string || '{}') as ChatBotProps;
    } catch (e) {
      console.log('no route query params detected, skipping');
      return {} as ChatBotProps;
    }
  };

  return (
    <div
      className="App"
      style={{
        background: `url(${Background})`,
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      <div ref={navHeaderRef} className="nav-header">
        <a href="/">
          <PyyplLogo className="logo" />
        </a>
        <div
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            fontSize: '0.5em',
            color: '#797979',
          }}
        >
          {PYYPL_WEB_APP_VERSION}
        </div>
      </div>

      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/signup" component={SignUp} /> */}
          <Route
            exact
            path="/chat-bot"
            render={() => (<ChatBot userData={parseChatBotRouteQueryParams()} navHeaderRef={navHeaderRef} />)}
          />
          <PrivateRoute exact path="/kyc/welcome" component={Welcome} />
          <PrivateRoute exact path="/kyc/verify" component={Verify} />
          <Route path="*">
            {/* <Redirect to="/signup" /> */}
            <Redirect to="/chat-bot" />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
