import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { hexColorOpacity } from '../../lib/utils';

export const COLORS = {
  primary: '#34C5BF',
  secondary: '#5A2AEA',
  secondary2: hexColorOpacity('#5A2AEA', 0.6),
  success: '#18AA41',
  info: '#5A2AEA',
  warning: '#FFBF00',
  error: '#9A3131',
  text: '#484848',
  textAccent: '#797979',
  background: '#FFFFFF',
  background2: '#F6F6F6',
  backgroundAccent: '#E4DFFB',
};

const defaultTheme = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    fontFamily: 'Roboto,sans-serif',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  m10: {
    margin: '10px auto',
  },
  m30: {
    margin: '30px auto',
  },
  p50: {
    padding: '50px !important',
  },
  details: {
    textAlign: 'left',
    margin: 'auto',
    '& > .MuiGrid-item': {
      padding: '20px 15px',
    },
  },
  chatTopics: {
    padding: '6px 10px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 8px 1px rgba(0, 0, 0, 0.08)',
    color: '#34c5bf',
    textDecoration: 'none',
    cursor: 'pointer',
    textTransform: 'none',
  },
  chatTopicsScrollBar: {
    width: '100%',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    padding: '10px 0 10px 0',
  },
  avatar: {
    width: '20px',
    height: '20px',
    border: '5px solid transparent',
    boxShadow: '0 0 10px rgb(0 0 0 / 30%)',
    display: 'inline-block',
    margin: '0 10px',
  },
  flagIcon: {
    width: 16,
    height: 16,
    borderRadius: 8,
    objectFit: 'cover',
    alignSelf: 'center',
  },
  fontSmall: {
    fontSize: '0.8rem',
  },
  iconSuccess: {
    color: COLORS.success,
    fontSize: '10rem',
  },
  iconError: {
    color: COLORS.error,
    fontSize: '10rem',
  },
  fontSuccess: {
    color: COLORS.success,
  },
  fontError: {
    color: COLORS.error,
  },
  fontWarning: {
    color: COLORS.warning,
  },
  box: {
    background: COLORS.background2,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '16px',
    padding: '15px',
    margin: '15px',
  },
  buttonLoading: {
    backgroundColor: COLORS.text,
    color: COLORS.textAccent,
    cursor: 'pointer',
    minWidth: '175px',
  },
  buttonReady: {
    backgroundColor: COLORS.primary,
    color: COLORS.background,
    cursor: 'pointer',
    minWidth: '175px',
  },
  buttonReadyOutline: {
    backgroundColor: '#997CF1',
    color: COLORS.primary,
    cursor: 'pointer',
  },
  buttonLoadingOutline: {
    backgroundColor: COLORS.text,
    color: COLORS.textAccent,
    cursor: 'pointer',
  },
  buttonOutlined: {
    border: 'none',
    color: COLORS.secondary2,
  },
  PyyplHeaderTitle: {
    fontWeight: 900,
    color: COLORS.secondary,
    fontSize: '40px',
    lineHeight: '48px',
    textAlign: 'center',
    maxWidth: '600px',
    marginTop: '20px',
    margin: 'auto',
  },
  PyyplHeaderTagline: {
    color: COLORS.textAccent,
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 900,
    textAlign: 'center',
    textTransform: 'uppercase',
    maxWidth: '600px',
    margin: 'auto',
  },
  PyyplHeaderSubtitle: {
    color: COLORS.text,
    lineHeight: '24px',
    fontWeight: 400,
    textAlign: 'center',
    maxWidth: '600px',
    margin: 'auto',
  },
  pyyplModal: {
    margin: '50px',
    '& > div:first-child': {
      backgroundColor: `${hexColorOpacity(COLORS.backgroundAccent, 0.8)}!important`,
      mixBlendMode: 'normal',
      backdropFilter: 'blur(10px)',
    },
  },
  pyyplModalTitle: {
    color: COLORS.success,
    fontSize: '40px',
    lineHeight: '48px',
    fontWeight: 900,
  },
  pyyplModalContent: {
    height: 'auto',
    margin: '16px auto',
    width: '100%',
    textAlign: 'center',
  },
  pyyplModalHeader: {
    color: COLORS.textAccent,
  },
  pyyplModalBody: {
    color: COLORS.text,
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 400,
  },
  pyyplModalActions: {
    height: 'auto',
    margin: '0 auto',
    width: '100%',
    textAlign: 'center',
  },
  pyyplModalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: COLORS.background,
    textAlign: 'center',
    width: '100%',
    maxWidth: '826px',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '40px 0',
    borderRadius: '8px',
  },
});

export default defaultTheme;
