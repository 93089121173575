import { CountryCode } from 'libphonenumber-js';
import React from 'react';

export type MayBe<T> = T | null;

export enum KycRequirementType {
  GOVERNMENT_IDENTIFICATION_DOCUMENT = 'GOVERNMENT_IDENTIFICATION_DOCUMENT',
  SELFIE_PHOTO = 'SELFIE_PHOTO',
  ADDRESS_INFO = 'ADDRESS_INFO',
  SOURCE_OF_FUNDS = 'SOURCE_OF_FUNDS',
}

export enum ServiceFeatureName {
  // Load Money - Acquirer
  LOAD_MONEY = 'LOAD_MONEY',

  // Send Money - PNW
  SEND_MONEY = 'SEND_MONEY',

  // Exchange - PNW
  EXCHANGE_CRYPTO = 'EXCHANGE_CRYPTO',

  // Card - Issuer
  VIRTUAL_CARD = 'VIRTUAL_CARD',
  VIRTUAL_CARD_REQUEST = 'VIRTUAL_CARD_REQUEST',
  PHYSICAL_CARD = 'PHYSICAL_CARD',
  PHYSICAL_CARD_REQUEST = 'PHYSICAL_CARD_REQUEST',
  PHYSICAL_CARD_ACTIVATE = 'PHYSICAL_CARD_ACTIVATE',

  // Misc
  INSURANCE = 'INSURANCE',
  AIRTIME = 'AIRTIME',
  BILL_PAYMENTS = 'BILL_PAYMENTS',
  DEBENTURE_BONDS = 'DEBENTURE_BONDS',
  DEBENTURE_INVESTMENT = 'DEBENTURE_INVESTMENT',
  INVITE_FRIEND = 'INVITE_FRIEND',
  PAYME = 'PAYME',

  // Dealer - Core
  DEALER_NETWORK = 'DEALER_NETWORK',
  DEALER_NETWORK_ACTIVATE = 'DEALER_NETWORK_ACTIVATE',
  MASTER_DEALER_NETWORK = 'MASTER_DEALER_NETWORK',
  EMPLOYEE_NETWORK = 'EMPLOYEE_NETWORK',

  // KYC
  BASE_KYC = 'BASE_KYC',
}
export type ListSourceOfFundsOptionsIcomeRanges = {
  min: number;
  max: number;
  level: number;
};

export type ListSourceOfFundsOptionsProfessions = {
  name: string;
  description: string;
  riskScore: number;
  hasSector: boolean;
};

export type ListSourceOfFundsOptionsSubSectors = {
  name: string;
  description: string;
  riskScore: number;
};

export type ListSourceOfFundsOptionsSectors = {
  name: string;
  description: string;
  subSectors: [ListSourceOfFundsOptionsSubSectors];
};

export type ListSourceOfFundsSourceOfFund = {
  name: string;
  description: string;
};
export type ListSourceOfFundsOptions = {
  incomeRanges: ListSourceOfFundsOptionsIcomeRanges[];
  professions: ListSourceOfFundsOptionsProfessions[];
  sectors: ListSourceOfFundsOptionsSectors[];
  sourceOfFunds: ListSourceOfFundsSourceOfFund[];
};

export type IncomeRangeInput = {
  level: number;
};

export type CreateSourceOfFundsAttributeInput = {
  sourceOfFunds?: MayBe<string>;
  incomeRange?: MayBe<IncomeRangeInput>;
  profession?: MayBe<string>;
  sector?: MayBe<string>;
  subSector?: MayBe<string>;
};

export type ListSourceOfFundsOptionsQuery = {
  listDueDiligenceSourceOfFundsOptions: ListSourceOfFundsOptions;
};
export type ListSourceOfFundsOptionsQueryVariables = {
  countryCode: string;
};

export type CreateSourceOfFundsAttributeMutationVariables = {
  userId: string;
  input: CreateSourceOfFundsAttributeInput;
};

export enum UserSensitiveAttributeType {
  AmlctfCompliance = 'AMLCTF_COMPLIANCE',
  TermsAndServicesAccepted = 'TERMS_AND_SERVICES_ACCEPTED',
  DateOfBirth = 'DATE_OF_BIRTH',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  SelfiePhoto = 'SELFIE_PHOTO',
  GovernmentIdentificationDocument = 'GOVERNMENT_IDENTIFICATION_DOCUMENT',
  Passcode = 'PASSCODE',
  AddressInfo = 'ADDRESS_INFO',
  DelinquentDebenturePayment = 'DELINQUENT_DEBENTURE_PAYMENT',
  CreditScore = 'CREDIT_SCORE',
  DebenturePreEligible = 'DEBENTURE_PRE_ELIGIBLE',
  VerificationSession = 'VERIFICATION_SESSION',
  ComplianceScreening = 'COMPLIANCE_SCREENING',
  SourceOfFunds = 'SOURCE_OF_FUNDS',
}

export enum UserSensitiveAttributeStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Blocked = 'BLOCKED',
  InReview = 'IN_REVIEW',
  Redo = 'REDO',
  Provisional = 'PROVISIONAL',
  Incomplete = 'INCOMPLETE',
}

export enum DueDiligenceDocuments {
  PASSPORT = 'PASSPORT',
  IDCARD = 'IDCARD',
  RESIDENCY_CARD = 'RESIDENCY_CARD',
  TWO_PHOTOS = 'TWO_PHOTOS',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  OTHER = 'OTHER',
}

export enum UserSensitiveAttributeProvider {
  RegulaForensics = 'REGULA_FORENSICS',
  Veriff = 'VERIFF',
  Acuant = 'ACUANT',
  Complyadvantage = 'COMPLYADVANTAGE',
}

export type UserSensitiveAttribute = {
  userId: string;
  userSensitiveAttributeMetaData: Record<string, any>;
  userSensitiveAttributeType: UserSensitiveAttributeType;
  userSensitiveAttributeValue: string;
  userSensitiveAttributeId: string;

  // userSensitiveAttributeStatus: UserSensitiveAttributeStatus
  // userSensitiveAttributeExpiresAt: string
  // userSensitiveAttributeDocumentNumber: string
  // userSensitiveAttributeDocumentType: DueDiligenceDocuments
  // userSensitiveAttributeProvider: UserSensitiveAttributeProvider
  // userSensitiveAttributeUrl: string
  // userSensitiveAttributeHash: string
  // userSensitiveAttributeSignature: string
  // createdAt: string
  // updatedAt: string
};
export type CreateSourceOfFundsAttributeMutation = {
  createSourceOfFundsAttribute?: MayBe<UserSensitiveAttribute>;
};
export type GetUserQuery = {
  getUser: MayBe<User>;
};
export type GetUserQueryVariables = {
  userId: string;
};
export type ListAccountsQuery = {
  listAccounts: MayBe<{
    data: Account[]
  }>;
};
export type ListServiceFeaturesQuery = {
  listServiceFeatures: MayBe<ServiceFeature[]>
}
export type ListServiceFeaturesQueryVariables = {
  userId: string;
  accountId: string
}
export enum KycProviderName {
  VERIFF='VERIFF',
  REGULA='REGULA'
}
export type KycProvider = {
  kycProviderName: KycProviderName
  kycPermittedDocuments: [DueDiligenceDocuments]
}
export type ListKycProvidersQuery = {
  listKycProviders: MayBe<KycProvider[]>
}
export type IDVSession = {
  id: string
  url: string
  vendorData: string
  host: string
  status: string
  sessionToken: string
}
export type GenerateIDVSessionMutation = {
  generateIDVSession: MayBe<IDVSession>
}
export type CreateAddressInfoInputMutationVariables = {
  userId: string;
  input: AddressInfoInput
};
export type CreateAddressInfoInputMutation = {
  createAddressInfo: MayBe<AddressInfo>
}
export type AddressInfoInput = {
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  state?: string;
  country: string;
};

export type AddressInfo = {
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  state?: string;
  country: string;
};

export enum ServiceFeatureStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export type ServiceFeatureUnavailableReason = {
  serviceFeatureUnavailableReasonCategory: string;
  serviceFeatureUnavailableReasonName: string;
};

export type ServiceFeature = {
  serviceFeatureName: ServiceFeatureName;
  serviceFeatureStatus: ServiceFeatureStatus;
  serviceFeatureUnavailableReasons?: ServiceFeatureUnavailableReason[];
};

export enum KycRequirementKey {
  ID = 'id',
  ADDRESS_INFO = 'address-info',
  SOURCE_OF_FUNDS = 'source-of-funds',
}

export type KycRequirementConfig = {
  kycRequirementKey: KycRequirementKey;
  kycRequirementType: KycRequirementType;
  kycRequirementIcon: React.ReactElement;
  kycRequirementTitle: string;
  kycRequirementDescription: string;
  kycRequirementStatus?: string;
  kycRequirementOrder: number;
};

export type DueDiligenceDocumentConfig = {
  documentType: DueDiligenceDocuments;
  documentIcon: React.ReactElement;
  documentTitle: string;
  documentDescription: string;
};

export type User = {
  userId: string;
  nickname: string;
  phoneNumber: string;
  assignedOperatingCountry: CountryCode;
};

export type Account = {
  accountId: string;
  name: string;
  type: string;
  defaultCurrency: string;
};

export type DeviceKeys = {
  publicKey: string
  privateKey: string
};

export type GeoLocationData = {
  accuracy: number
  area_code: string
  asn: number
  city: string
  continent_code: string
  country: string
  country_code: string
  country_code3: string
  ip: string
  latitude: string
  longitude: string
  organization: string
  organization_name: string
  region: string
  timezone: string
}

export type TermsAndConditionsSecuredData = {
  hash: string
  signature: string
}
