import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { useEffect, useState } from 'react';
import PyyplDownloadApp from '../../components/PyyplDownloadApp';
import PyyplLoader from '../../components/PyyplLoader';
import PyyplModal, { PyyplModalConfig } from '../../components/PyyplModal';
import { useAuthStore } from '../../store/auth.store';
import { useKycStore } from '../../store/kyc.store';
import { KycRequirementKey } from '../../types';
import AddressInfo from './Verify/AddressInfo';
import IdentityInfo from './Verify/IdentityInfo';
import SourceOfFunds from './Verify/SourceOfFunds';

const Verify = () => {
  const [pyyplModalConfig, setPyyplModalConfig] = useState<PyyplModalConfig | null>(null);
  const kycStore = useKycStore();
  const authStore = useAuthStore();
  const [isLoading, setIsLoading] = useState<boolean>();

  useEffect(() => {
    setIsLoading(true);
    /**
     * @description
     * make sure user data is initialized correctly
     * in case if during signup some values in user record is not initialized
     * i.e. user.assignedOperatingCountry
     */
    Auth.currentAuthenticatedUser()
      .then((res: CognitoUser) => {
        const username = res.getUsername();
        authStore.initUserData(username);
      })
      .catch(handleErrorResponse)
      .finally(() => setIsLoading(false));
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleErrorResponse = (error?: Error | Record<string, any>) => {
    const { message } = error || {};
    console.log('error', message);
    switch (true) {
      default:
        setPyyplModalConfig({
          title: 'Something Went Wrong!',
          body: 'We faced some technical issues while trying to create your account. Please try again later!',
          variant: 'error',
        });
        break;
    }
  };

  const handleSuccess = (response?: Record<string, any>) => {
    const { isIdentitySubmitted } = response || {};
    if (isIdentitySubmitted) {
      setPyyplModalConfig({
        title: 'Verifying Your Account 🕚',
        // eslint-disable-next-line max-len
        body: 'Thank you for registering with us. We are currently verifying your account. You can check the status of your verification by downloading our app and following the instructions provided.',
        variant: 'warning',
        Content: <PyyplDownloadApp />,
      });
    } else {
      kycStore.setCurrentKycStateKey(kycStore.nextKycStateKey);
    }
  };

  const renderStepComponent = () => {
    switch (kycStore.currentKycStateKey) {
      case KycRequirementKey.ADDRESS_INFO:
        return (
          <AddressInfo
            onError={handleErrorResponse}
            onSuccess={handleSuccess}
          />
        );
      case KycRequirementKey.SOURCE_OF_FUNDS:
        return (
          <SourceOfFunds
            onError={handleErrorResponse}
            onSuccess={handleSuccess}
          />
        );
      case KycRequirementKey.ID:
        return (
          <IdentityInfo
            onError={handleErrorResponse}
            onSuccess={handleSuccess}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading ? <PyyplLoader loading={isLoading} /> : renderStepComponent()}
      {!!pyyplModalConfig && <PyyplModal config={pyyplModalConfig} />}
    </>
  );
};

export default Verify;
