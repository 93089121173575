/* eslint-disable max-len */
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { parsePhoneNumber } from 'libphonenumber-js';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import theme, { COLORS } from '../../assets/styles/theme';
import CountryFlag from '../../components/CountryFlag';
import PyyplHeader from '../../components/PyyplHeader';
import PyyplLoader from '../../components/PyyplLoader';
import PyyplModal, { PyyplModalConfig } from '../../components/PyyplModal';
import { listServiceFeatures } from '../../lib/graphql';
import { useAuthStore } from '../../store/auth.store';
import { useKycStore } from '../../store/kyc.store';
import {
  KycRequirementConfig,
  KycRequirementKey,
  KycRequirementType,
  ServiceFeature,
  ServiceFeatureName,
} from '../../types';
import { KycRequirementsConfig } from './Kyc.constants';

const Welcome = () => {
  const [requiredKycStates, setRequiredKycStates] = useState<KycRequirementConfig[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const history = useHistory();
  const kycStore = useKycStore();
  const authStore = useAuthStore();

  const { user, defaultUserAccount } = authStore;
  const userId = user?.userId;
  const accountId = defaultUserAccount?.accountId;
  const [pyyplModalConfig, setPyyplModalConfig] = useState<PyyplModalConfig>();
  const handleErrorResponse = (error?: Error | Record<string, any>) => {
    const { message } = error || {};
    console.log('error:::', message);
    switch (true) {
      default:
        setPyyplModalConfig({
          title: 'Something Went Wrong!',
          body: 'We faced some technical issues while trying to create your account. Please try again later!',
          variant: 'error',
        });
        break;
    }
  };
  const countryCode = (user?.phoneNumber && parsePhoneNumber(user?.phoneNumber)?.country) || 'AE';
  const countryName = new Intl.DisplayNames(['en'], { type: 'region' }).of(
    countryCode,
  );

  useEffect(() => {
    authStore
      .initUserAccountsData()
      .then(() => {
        /* user accounts data initialized */
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
  }, []);

  useEffect(() => {
    if (userId && accountId && !_.isEmpty(requiredKycStates)) {
      setLoading(false);
    }

    if (userId && accountId && _.isEmpty(requiredKycStates)) {
      const init = async () => {
        const serviceFeaturesData = await listServiceFeatures(
          userId,
          accountId,
        );

        const { serviceFeatureUnavailableReasons } = (serviceFeaturesData || []).find(
          (serviceFeatureData) =>
            serviceFeatureData?.serviceFeatureName
              === ServiceFeatureName.BASE_KYC,
        ) || ({} as ServiceFeature);

        const baseKycRequirements = serviceFeatureUnavailableReasons?.map(
          (serviceFeatureUnavailableReason) => {
            const [kycRequirementType, kycRequirementStatus] = serviceFeatureUnavailableReason.serviceFeatureUnavailableReasonName.split(
              '#',
            );
            return {
              ...KycRequirementsConfig[
                kycRequirementType as KycRequirementType
              ],
              kycRequirementStatus,
            };
          },
        ) as KycRequirementConfig[];

        const sortedBaseKycRequirements = _.sortBy(
          baseKycRequirements,
          'kycRequirementOrder',
        );

        setRequiredKycStates(sortedBaseKycRequirements);
        kycStore.setKycStateKeys(
          _.chain(sortedBaseKycRequirements)
            .map('kycRequirementKey')
            .uniq()
            .value() as KycRequirementKey[],
        );
        kycStore.setCurrentKycStateKey(
          sortedBaseKycRequirements[0].kycRequirementKey,
        );
      };
      init()
        .finally(() => console.log('Welcome initialized'))
        .catch((err) => {
          console.error('init error:', err);
          handleErrorResponse(err);
        });
    }
  }, [userId, accountId, requiredKycStates]);

  if (loading) {
    return <PyyplLoader loading={loading} />;
  }

  return (
    <>
      <PyyplHeader
        tagline="VERIFY YOUR ACCOUNT"
        title="Account Registred"
        subtitleStyle={{ color: COLORS.success }}
        subtitle={`Congratulations <strong>${user?.nickname}</strong>, Your number <strong>${user?.phoneNumber}</strong> has been successfully registered with us.`}
      />
      {!!pyyplModalConfig && <PyyplModal config={pyyplModalConfig} />}
      <Box component="span" m={1}>
        <Container maxWidth="sm">
          <Card className={classes.box}>
            <CardContent>
              <Grid container spacing={1} justifyContent="center">
                <Grid container spacing={1} className={classes.details}>
                  <Grid item xs={12}>
                    <>
                      <Typography
                        color="textSecondary"
                        className={classes.fontSmall}
                      >
                        Country
                      </Typography>
                      <Grid
                        item
                        xs={11}
                        style={{
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: '20px',
                          lineHeight: '24px',
                          color: COLORS.text,
                        }}
                      >
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                          <CountryFlag
                            countryCode={user?.assignedOperatingCountry || 'AE'}
                            style={{ alignSelf: 'center', marginRight: 8 }}
                          />
                          {countryName}
                        </div>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>

        <Typography
          className={classes.PyyplHeaderSubtitle}
          style={{ color: COLORS.secondary, paddingTop: '20px' }}
        >
          Below is a list of the information you’ll need to provide for the
          selected country. Please follow the instructions carefully and make
          sure you have all of your necessary documents ready before proceeding
          to the next step.
        </Typography>

        <Container maxWidth="sm" style={{ margin: '30px auto' }}>
          <Card className={classes.box}>
            <CardContent>
              <Grid container spacing={1} justifyContent="center">
                <Grid container spacing={1} className={classes.details}>
                  {requiredKycStates?.map((requiredKycState, index) => (
                    <Grid
                      item
                      xs={12}
                      key={requiredKycState.kycRequirementType}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Grid item xs={1}>
                        {requiredKycState.kycRequirementIcon}
                      </Grid>
                      <Grid
                        item
                        xs={11}
                        style={{
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: '20px',
                          lineHeight: '24px',
                          color: COLORS.text,
                        }}
                      >
                        {`${index + 1}. ${
                          requiredKycState.kycRequirementTitle
                        }`}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
        <Container maxWidth="sm" style={{ marginBottom: 20 }}>
          <Grid container spacing={1} justifyContent="center">
            <Typography className={classes.PyyplHeaderSubtitle}>
              To complete the account setup process, please click on the “Verify
              account” button and follow the instructions displayed on the
              screen. Alternatively, you can download our app and complete the
              verification process within the app.
            </Typography>
          </Grid>
        </Container>
        <Container maxWidth="sm">
          <Grid container spacing={1} justifyContent="center">
            <Grid
              container
              direction="column"
              spacing={0}
              className={classes.details}
              alignItems="center"
            >
              <Button
                className={classes.buttonOutlined}
                variant="text"
                style={{ minWidth: '175px' }}
                onClick={() =>
                  window.open(authStore.onboardingRequestDeepLink!)}
              >
                Download App
              </Button>
              <Grid item>
                <Button
                  className={classes.buttonReady}
                  variant="contained"
                  style={{ minWidth: '175px' }}
                  onClick={() => history.push('/kyc/verify')}
                >
                  Verify Account
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Welcome;
