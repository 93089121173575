import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import theme from '../assets/styles/theme';

type HeaderProps = {
  tagline?: string;
  title?: string;
  subtitle?: string;
  subtitleStyle?: CSSProperties;
};

const PyyplHeader = (props: HeaderProps) => {
  const {
    tagline, title, subtitleStyle, subtitle,
  } = props;
  const useStyles = makeStyles(theme);
  const classes = useStyles();

  return (
    <>
      {tagline && (
        <Typography className={classes.PyyplHeaderTagline}>
          <span dangerouslySetInnerHTML={{ __html: tagline }} />
        </Typography>
      )}

      {title && (
        <Typography className={classes.PyyplHeaderTitle} style={subtitleStyle}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Typography>
      )}

      {subtitle && (
        <Typography className={classes.PyyplHeaderSubtitle}>
          <span dangerouslySetInnerHTML={{ __html: subtitle }} />
        </Typography>
      )}
    </>
  );
};

export default PyyplHeader;
