import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import {
  AddressInfoInput,
  CreateAddressInfoInputMutation,
  CreateAddressInfoInputMutationVariables,
  CreateSourceOfFundsAttributeInput,
  CreateSourceOfFundsAttributeMutation,
  CreateSourceOfFundsAttributeMutationVariables,
  GenerateIDVSessionMutation,
  GetUserQuery,
  GetUserQueryVariables,
  ListAccountsQuery,
  ListKycProvidersQuery,
  ListServiceFeaturesQuery,
  ListServiceFeaturesQueryVariables,
  ListSourceOfFundsOptionsQuery,
} from '../types';
import { getCurrentSessionJwtToken } from './auth';

const request = async <
  ReturnType = Record<string, any>,
  Params = Record<string, any> | null | undefined
>(
  query: string,
  params?: Params,
) => {
  const authToken = await getCurrentSessionJwtToken();
  const response = (await API.graphql(
    {
      query,
      ...(params && { variables: params }),
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      authToken: `Bearer ${authToken}`,
    },
    /* {
    'x-pyypl-devicehardwareid': getSessionParam('deviceHardwareId') || '',
  } */
  )) as GraphQLResult<ReturnType>;
  if (response.errors) {
    console.warn('APIGraphQL: res.errors:', response.errors);
    throw response;
  }
  return response;
};

export const listSourceOfFunds = async (countryCode: string) => {
  const query = /* GraphQL */ `
    query ListDueDiligenceSourceOfFundsOptions($countryCode: String) {
      listDueDiligenceSourceOfFundsOptions(countryCode: $countryCode) {
        incomeRanges {
          level
          max
          min
        }
        professions {
          name
          riskScore
          description
          hasSector
        }
        sectors {
          name
          description
          subSectors {
            name
            description
          }
        }
        sourceOfFunds {
          name
          description
        }
      }
    }
  `;
  const variables = { countryCode };
  const res = await request<ListSourceOfFundsOptionsQuery>(query, variables);
  return res.data?.listDueDiligenceSourceOfFundsOptions;
};

export const createSourceOfFunds = async (userId: string, input: CreateSourceOfFundsAttributeInput) => {
  const query = /* GraphQL */ `
    mutation CreateSourceOfFundsAttribute(
      $userId: ID
      $input: CreateSourceOfFundsAttributeInput
    ) {
      createSourceOfFundsAttribute(userId: $userId, input: $input) {
        userId
        userSensitiveAttributeMetaData
        userSensitiveAttributeType
        userSensitiveAttributeId
        userSensitiveAttributeValue
        # userSensitiveAttributeStatus
        # userSensitiveAttributeExpiresAt
        # userSensitiveAttributeDocumentNumber
        # userSensitiveAttributeDocumentType
        # userSensitiveAttributeProvider
        # userSensitiveAttributeUrl
        # userSensitiveAttributeHash
        # userSensitiveAttributeSignature
        # createdAt
        # updatedAt
      }
    }
  `;
  const variables = { userId, input };
  const res = await request<CreateSourceOfFundsAttributeMutation, CreateSourceOfFundsAttributeMutationVariables>(query, variables);
  return res.data?.createSourceOfFundsAttribute;
};

export const getUser = async (userId: string) => {
  const query = /* GraphQL */ `
    query GetUser($userId: ID) {
      getUser(userId: $userId) {
        userId
        nickname
        phoneNumber
        assignedOperatingCountry
      }
    }
  `;

  const variables = { userId };
  const response = await request<GetUserQuery, GetUserQueryVariables>(
    query,
    variables,
  );
  return response.data?.getUser;
};

export const listAccounts = async () => {
  const query = /* GraphQL */ `
    query listAccounts {
      listAccounts {
        data {
          accountId
          name
          type
          defaultCurrency
        }
      }
    }
  `;
  const variables = null;
  const response = await request<ListAccountsQuery, null>(query, variables);
  return response.data?.listAccounts?.data || [];
};

export const listServiceFeatures = async (userId: string, accountId: string) => {
  const query = /* GraphQL */ `
    query ListServiceFeatures($userId: ID, $accountId: ID) {
      listServiceFeatures(userId: $userId, accountId: $accountId) {
        serviceFeatureName
        serviceFeatureStatus
        serviceFeatureUnavailableReasons {
          serviceFeatureUnavailableReasonCategory
          serviceFeatureUnavailableReasonName
        }
      }
    }
  `;
  const variables = { userId, accountId };
  const response = await request<ListServiceFeaturesQuery, ListServiceFeaturesQueryVariables>(query, variables);
  return response.data?.listServiceFeatures;
};

export const listKycProviders = async () => {
  const query = /* GraphQL */ `
    query ListKycProviders {
      listKycProviders {
        kycProviderName
        kycPermittedDocuments
      }
    }
  `;

  const response = await request<ListKycProvidersQuery>(query);
  return response.data?.listKycProviders;
};

export const createAddressInfo = async (userId: string, input: AddressInfoInput) => {
  const mutation = /* GraphQL */ `
    mutation ($userId: ID!, $input: AddressInfoInput) {
      createAddressInfo(userId: $userId, input: $input) {
        address1
        address2
        city
        state
        zipCode
        country
      }
    }
  `;
  const variables = { userId, input };
  const response = await request<CreateAddressInfoInputMutation, CreateAddressInfoInputMutationVariables>(mutation, variables);
  return response.data?.createAddressInfo;
};

export const generateIDVSession = async () => {
  const mutation = /* GraphQL */ `
    mutation generateIDVSession {
      generateIDVSession {
        id
        url
        vendorData
        host
        status
        sessionToken
      }
    }
  `;
  const res = await request<GenerateIDVSessionMutation>(mutation);
  return res.data?.generateIDVSession;
};
