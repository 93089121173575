import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import theme, { COLORS } from '../../../assets/styles/theme';
import PyyplHeader from '../../../components/PyyplHeader';
import PyyplLoader from '../../../components/PyyplLoader';
import { generateIDVSession, listKycProviders } from '../../../lib/graphql';
import { useKycStore } from '../../../store/kyc.store';
import {
  DueDiligenceDocumentConfig,
} from '../../../types';
import { DocumentsConfig } from '../Kyc.constants';

type IdentityInfoProps = {
  onError: (error?: Error | Record<string, any>) => void;
  onSuccess: (response?: Record<string, any>) => void;
};

const IdentityInfo = (props: IdentityInfoProps) => {
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const { onError = () => {}, onSuccess = () => {} } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [allowedIdentityDocuments, setAllowedIdentityDocuments] = useState<DueDiligenceDocumentConfig[]>();
  const kycStore = useKycStore();

  useEffect(() => {
    // small delay so that user won't see Verifying Your Account modal initially
    if (kycStore.kycIDVSession) {
      setTimeout(() => onSuccess({ isIdentitySubmitted: true }), 500);
    } else {
      const init = async () => {
        const kycProviders = (await listKycProviders()) || [];
        const { kycPermittedDocuments } = kycProviders[0];
        const allowedDocuments = kycPermittedDocuments.map(
          (kycPermittedDocument) => ({
            ...DocumentsConfig[kycPermittedDocument],
          }),
        );
        setAllowedIdentityDocuments(allowedDocuments);
        setLoading(false);
      };

      init()
        .finally(() => console.log('Provider data initialized'))
        .catch((err) => onError(err));
    }
  }, [kycStore.kycIDVSession]);

  if (loading) {
    return <PyyplLoader loading={loading} />;
  }

  const startIdentityVerification = async () => {
    setSubmitLoading(true);
    const { id, url } = (await generateIDVSession()) || {};
    kycStore.setKycIDVSession(id as string);
    window.location.href = url as string;
  };

  return (
    <>
      <PyyplHeader
        tagline="GETTING STARTED"
        title="Type of document"
        subtitle="You can provide any of the document types from the list below to verify your identity."
      />
      <Box component="span" m={1}>
        <Container maxWidth="sm" style={{ marginTop: '20px' }}>
          <Card className={classes.box}>
            <CardContent>
              <Grid container spacing={1} justifyContent="center">
                <Grid container spacing={1} className={classes.details}>
                  {allowedIdentityDocuments?.map((allowedIdentityDocument) => (
                    <Grid
                      item
                      xs={12}
                      key={allowedIdentityDocument.documentType}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Grid item xs={2}>
                        {allowedIdentityDocument.documentIcon}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'inline-block',
                          alignItems: 'center',
                        }}
                      >
                        <Grid
                          item
                          xs={10}
                          style={{
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '20px',
                            lineHeight: '24px',
                            color: COLORS.text,
                          }}
                        >
                          {allowedIdentityDocument.documentTitle}
                        </Grid>
                        <Typography
                          color="textSecondary"
                          className={classes.fontSmall}
                        >
                          {allowedIdentityDocument.documentDescription}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>

        <Container maxWidth="sm" style={{ margin: '30px auto' }}>
          <Grid container spacing={1} justifyContent="center">
            <Grid
              container
              spacing={1}
              className={classes.details}
              justifyContent="center"
            >
              <Typography className={classes.PyyplHeaderSubtitle} style={{ color: COLORS.secondary, marginBottom: 16 }}>
                Hint: For quicker verification, please use your passport or ID from the country linked with your registered phone number.
              </Typography>
              <Button
                startIcon={submitLoading ? <CircularProgress size={14} /> : ''}
                variant="contained"
                style={{ minWidth: '175px' }}
                onClick={() => startIdentityVerification()}
                className={
                  submitLoading ? classes.buttonLoading : classes.buttonReady
                }
                disabled={submitLoading}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default IdentityInfo;
