import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import theme from '../../../assets/styles/theme';
import PyyplHeader from '../../../components/PyyplHeader';
import PyyplLoader from '../../../components/PyyplLoader';
import { mapTextFieldErrorProps } from '../../../lib/formHelpers';
import { createSourceOfFunds, listSourceOfFunds } from '../../../lib/graphql';
import { formatCurrency } from '../../../lib/utils';
import { useAuthStore } from '../../../store/auth.store';
import { ListSourceOfFundsOptions } from '../../../types';

export type SourceOfFundsFormData = yup.InferType<
  typeof sourceOfFundsFormSchema
>;

const sourceOfFundsFormSchema = yup
  .object()
  .shape({
    profession: yup.string().required(),
    sector: yup
      .string()
      .when('hasSector', ([hasSector], schema) =>
        (hasSector ? schema.required() : schema.oneOf([''])),
      ),
    subSector: yup
      .string()
      .when('hasSector', ([hasSector], schema) =>
        (hasSector ? schema.required() : schema.oneOf([''])),
      ),
    incomeRange: yup.string().required(),
    sourceOfFunds: yup.string().required(),
    hasSector: yup.boolean().optional(),
  })
  .required();

const defaultSourceOfFundsFieldsDefinition: SourceOfFundsFormData = {
  profession: '',
  sector: '',
  subSector: '',
  incomeRange: '',
  sourceOfFunds: '',
  hasSector: false,
};

type SourceOfFundsProps = {
  onError: (error?: Error | Record<string, any>) => void;
  onSuccess: (response?: Record<string, any>) => void;
};

const SourceOfFunds = (props: SourceOfFundsProps) => {
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const { onError = () => {}, onSuccess = () => {} } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const authStore = useAuthStore();
  const { user } = authStore;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setValue,
    watch,
  } = useForm({
    defaultValues: defaultSourceOfFundsFieldsDefinition,
    resolver: yupResolver(sourceOfFundsFormSchema),
    mode: 'onChange',
  });
  const professionValue = watch('profession');
  const sectorValue = watch('sector');
  const hasSectorValue = watch('hasSector');
  const defaultCurrency = authStore.defaultUserAccount?.defaultCurrency || 'AED';
  const [sourceOfFundsList, setSourceOfFundsList] = useState<ListSourceOfFundsOptions>();

  const subSectorsList = useMemo(() => {
    const sector = (sourceOfFundsList?.sectors || []).find(
      (el) => el.name === sectorValue,
    );
    if (sector) {
      return sector.subSectors;
    }
    setValue('subSector', '');
    return [];
  }, [sectorValue]);

  useEffect(() => {
    const _hasSector = (sourceOfFundsList?.professions || []).find(
      (el) => el.name === professionValue,
    )?.hasSector || false;
    setValue('hasSector', _hasSector);
    setValue('sector', '');
    setValue('subSector', '');
  }, [professionValue]);

  useEffect(() => {
    const init = async () => {
      const res = await listSourceOfFunds(user?.assignedOperatingCountry!);
      setSourceOfFundsList(res);
    };
    init()
      .then(() => console.log('SOF initialized'))
      .catch((err) => onError(err))
      .finally(() => setLoading(false));
  }, []);

  const onSubmit: SubmitHandler<SourceOfFundsFormData> = async (data) => {
    try {
      const { hasSector, incomeRange, ...sourceOfFundsInput } = data;
      await createSourceOfFunds(user?.userId!, {
        ...sourceOfFundsInput,
        incomeRange: { level: parseInt(incomeRange as string, 10) },
      });
      onSuccess({ sourceOfFundsSubmitted: true });
    } catch (error) {
      console.log('error createSourceOfFunds', error);
      onError(error as Error);
    }
  };

  if (loading) {
    return <PyyplLoader loading={loading} />;
  }

  return (
    <>
      <PyyplHeader
        tagline="GETTING STARTED"
        title="Source of funds"
        // eslint-disable-next-line max-len
        subtitle="To get started, please provide your source of funds by following the instructions below. This information will help us ensure that you have access to the features and services available in your area."
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box component="span" m={1}>
          <Container maxWidth="sm">
            <Card className={classes.box}>
              <CardContent>
                <Grid container spacing={1} justifyContent="center">
                  <Grid container spacing={1} className={classes.details}>
                    <Grid item xs={12}>
                      <Controller
                        name="profession"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth>
                            <InputLabel id={`${field.name}-label`}>
                              Occupation*
                            </InputLabel>
                            <Select
                              {...field}
                              {...mapTextFieldErrorProps(fieldState)}
                              disabled={isSubmitting}
                              required
                              labelId={`${field.name}-label`}
                              id={field.name}
                              label="Select"
                            >
                              {(sourceOfFundsList?.professions || []).map(
                                (el) => (
                                  <MenuItem value={el.name} key={el.name}>
                                    {el.description}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Controller
                    name="sector"
                    control={control}
                    render={({ field, fieldState }) =>
                      (hasSectorValue ? (
                        <Grid container spacing={1} className={classes.details}>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id={`${field.name}-label`}>
                                Sector*
                              </InputLabel>
                              <Select
                                {...field}
                                {...mapTextFieldErrorProps(fieldState)}
                                disabled={isSubmitting}
                                required={hasSectorValue}
                                labelId={`${field.name}-label`}
                                id={field.name}
                                label="Select"
                              >
                                {(sourceOfFundsList?.sectors || []).map(
                                  (el) => (
                                    <MenuItem value={el.name} key={el.name}>
                                      {el.description}
                                    </MenuItem>
                                  ),
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      ))}
                  />

                  <Controller
                    name="subSector"
                    control={control}
                    render={({ field, fieldState }) =>
                      (hasSectorValue ? (
                        <Grid container spacing={1} className={classes.details}>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id={`${field.name}-label`}>
                                Sub-sector*
                              </InputLabel>
                              <Select
                                {...field}
                                {...mapTextFieldErrorProps(fieldState)}
                                disabled={isSubmitting}
                                required={hasSectorValue}
                                labelId={`${field.name}-label`}
                                id={field.name}
                                label="Select"
                              >
                                {subSectorsList.map((el) => (
                                  <MenuItem value={el.name} key={el.name}>
                                    {el.description}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      ))}
                  />

                  <Grid container spacing={1} className={classes.details}>
                    <Grid item xs={12}>
                      <Controller
                        name="incomeRange"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth>
                            <InputLabel id={`${field.name}-label`}>
                              Income range
                              {` (${defaultCurrency})`}
                              *
                            </InputLabel>
                            <Select
                              {...field}
                              {...mapTextFieldErrorProps(fieldState)}
                              disabled={isSubmitting}
                              required
                              labelId={`${field.name}-label`}
                              id={field.name}
                              label="Select"
                            >
                              {(sourceOfFundsList?.incomeRanges || []).map(
                                (el) => (
                                  <MenuItem value={el.level} key={el.level}>
                                    {`${formatCurrency({
                                      value: el.min,
                                    })} - ${
                                      el.max ? formatCurrency({ value: el.max })
                                        : 'More'
                                    }`}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className={classes.details}>
                    <Grid item xs={12}>
                      <Controller
                        name="sourceOfFunds"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth>
                            <InputLabel id={`${field.name}-label`}>
                              Source of funds*
                            </InputLabel>
                            <Select
                              {...field}
                              {...mapTextFieldErrorProps(fieldState)}
                              disabled={isSubmitting}
                              required
                              labelId={`${field.name}-label`}
                              id={field.name}
                              label="Select"
                            >
                              {sourceOfFundsList?.sourceOfFunds.map((el) => (
                                <MenuItem value={el.name} key={el.name}>
                                  {el.description}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>

          <Container maxWidth="sm">
            <Grid container spacing={1} justifyContent="center">
              <Grid
                container
                spacing={1}
                className={classes.details}
                justifyContent="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={isSubmitting ? <CircularProgress size={14} /> : ''}
                  className={
                    !isValid || isSubmitting
                      ? classes.buttonLoading
                      : classes.buttonReady
                  }
                  disabled={!isValid || isSubmitting}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </form>
    </>
  );
};
export default SourceOfFunds;
