import AppStore from '../assets/images/appstore-badge.png';
import GooglePlay from '../assets/images/googleplay-badge.png';

const PyyplDownloadApp = () => (
  <div
    style={{
      height: 'auto',
      margin: '30px auto',
      width: '100%',
      textAlign: 'center',
    }}
  >
    <div>
      <a href="https://apps.apple.com/ae/app/pyypl/id1499841532">
        <img
          src={AppStore}
          alt="appstore-badge"
          style={{ margin: '0 8px' }}
          width={135}
          height={45}
        />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.pyypl&hl=en&gl=US&pli=1">
        <img
          src={GooglePlay}
          alt="googleplay-badge"
          style={{ margin: '0 8px' }}
          width={135}
          height={45}
        />
      </a>
    </div>
  </div>
);

export default PyyplDownloadApp;
