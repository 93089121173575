import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Input, Modal, TextField, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseOutlined, Send } from '@material-ui/icons';
import axios from 'axios';
import {
  LegacyRef, RefObject, useEffect, useRef, useState,
} from 'react';
import { Rating } from 'react-simple-star-rating';
import { v4 as uuid } from 'uuid';
import theme, { COLORS } from '../../assets/styles/theme';
import Header from '../../components/PyyplHeader';

const {
  REACT_APP_PYYPL_API_DOMAIN: PYYPL_API_DOMAIN,
} = process.env;

const chatBotMessageSessionId = uuid();

const TOPICS_DEFINITIONS = [
  { title: 'Refunds', message: 'I want to request a refund' },
  { title: 'Remittance', message: 'I want additional info on remittance' },
  { title: 'Manual Bank Transfer', message: 'I want to follow up on a bank transfer request' },
  { title: 'Ticket Follow Up', message: 'I want to follow up on one of my tickets' },
  { title: 'Card Delivery', message: 'I want an updated on card delivery' },
];

const CompletionCorrectionModal = (props: any) => {
  const { handleSubmitCompletionCorrection = () => {}, setShowModal, showModal } = props;
  const [completionCorrectionContent, setCompletionCorrectionContent] = useState<string>('');

  const useStyles = makeStyles(theme);
  const classes = useStyles();

  const dismissModal = () => {
    setShowModal(false);
  };

  return (
    <Modal
      open={showModal}
      onClose={(_event, reason) => {
        if (!['backdropClick', 'escapeKeyDown'].includes(reason)) {
          dismissModal();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.pyyplModal}
    >
      <Box className={classes.pyyplModalBox}>
        <div
          style={{ textAlign: 'right', padding: '0 20px', cursor: 'pointer' }}
        >
          <CloseOutlined onClick={() => dismissModal()} />
        </div>
        <div style={{ maxWidth: '460px', margin: 'auto', padding: '0 30px' }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className={classes.pyyplModalTitle}
            style={{ color: COLORS.warning }}
          >
            Completion Correction 🤔
          </Typography>

          <Typography
            id="modal-modal-description"
            className={classes.pyyplModalBody}
          >
            To help us improve
            {' '}
            <strong>Anna</strong>
            {' '}
            our assistant, please input completion correction below!
          </Typography>

          <div className={classes.pyyplModalContent}>
            <TextField
              fullWidth
              rows={5}
              multiline
              name="chatCompletionCorrection"
              required
              type="text"
              placeholder="Input suggested correction here..."
              value={completionCorrectionContent}
              error={!completionCorrectionContent}
              onChange={(e: any) => setCompletionCorrectionContent(e.target.value)}
            />
          </div>

          <div className={classes.pyyplModalActions}>
            <Button
              onClick={() => handleSubmitCompletionCorrection(completionCorrectionContent)}
              disabled={!completionCorrectionContent}
            >
              Submit
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export type ChatBotProps = {
  userData: Record<string, any>
  navHeaderRef: RefObject<HTMLDivElement>
};

const ChatBot = (props: ChatBotProps) => {
  const { userData, navHeaderRef } = props;
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const messagesEndRef = useRef({}) as LegacyRef<HTMLDivElement>;
  const [input, setInput] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showAgentSupport, setShowAgentSupport] = useState(false);
  const [chatMessageCompletionRating, setChatMessageCompletionRating] = useState<{
    id?: string,
    rate?: number,
    correction?: string,
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<{
    id?: string,
    role: string,
    content: string,
    timestamp: string
  }[]>([{
    role: 'bot',
    // eslint-disable-next-line
    content: 'Hey I\'m Anna, Pyypl Virtual assistant, to get started simply ask any question with the language you prefer. Oh and by the way I\'m still learning, so if I can\'t help you, I\'ll redirect you to additional resources.',
    timestamp: new Date().toLocaleTimeString(),
  }]);
  // @ts-ignore
  const { ReactNativeWebView } = window;

  const handleChange = (e: any) => setInput(e.target.value);

  useEffect(() => {
    if (ReactNativeWebView) {
      if (messages.length >= 2) {
        setShowAgentSupport(true);
      }
      // @ts-ignore
      navHeaderRef.current.style.display = 'none';
    }
    // @ts-ignore
    (messagesEndRef as RefObject<HTMLDivElement>)?.current.scrollIntoView({ behavior: 'smooth' });
  }, [ReactNativeWebView, messages]);

  const handleSubmitCompletionCorrection = (correction: string) => {
    setChatMessageCompletionRating({
      ...chatMessageCompletionRating,
      correction,
    });
    setShowModal(false);
  };

  const resolveChatBotMessageCompletionRating = async (): Promise<null> => {
    const { id, correction, rate } = chatMessageCompletionRating || {};
    if (!id) {
      return null;
    }

    if (rate && rate < 3 && !correction && userData?.isAgent) {
      setShowModal(true);
      return null;
    }

    await axios
      .post(
        `https://${PYYPL_API_DOMAIN}/webapp/rate-chat-bot-message-completion`,
        {
          chatBotMessageId: id,
          chatBotMessageCompletionRating: rate,
          ...(correction && { chatBotMessageCompletionCorrection: correction }),
        },
      ).then(() => {
        setChatMessageCompletionRating(null);
      }).catch((error) => {
        setChatMessageCompletionRating(null);
        console.log(error as Error);
      });
    return null;
  };

  const redirectToSupportAgent = (additionalMessages?: {
    id?: string,
    role: string,
    content: string,
    timestamp: string
  }[]) => {
    // @ts-ignore
    // eslint-disable-next-line max-len
    setTimeout(() => {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({
        // eslint-disable-next-line max-len
        redirect: '://open?_branch_referrer=H4sIAAAAAAAAA8soKSkottLXL6isLMjRK0ktLtFNLCjQy8nMy9Y3yShL8wz19qjKTwIAvik5LicAAAA%3D&link_click_id=944662166731818044',
        messages: [
          ...messages,
          ...(additionalMessages || []),
        ],
      }));
    }, 0);
  };

  useEffect(() => {
    setLoading(true);
    resolveChatBotMessageCompletionRating()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [chatMessageCompletionRating]);

  const handleTopicSubmission = (topic: string) => {
    const chat = [...(messages.length <= 10 ? messages : messages.slice(1))];
    const topicChat = [
      { role: 'user', content: topic, timestamp: new Date().toLocaleTimeString() },
      {
        role: 'bot',
        content: 'I will redirect you to one of our agents for immediate support 🕵',
        timestamp: new Date().toLocaleTimeString(),
      },
    ];
    setMessages([...chat, ...topicChat]);
    redirectToSupportAgent(topicChat);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    setInput('');
    const chat = [
      ...(messages.length <= 10 ? messages : messages.slice(1)),
      { role: 'user', content: input, timestamp: new Date().toLocaleTimeString() },
    ];
    setMessages([...chat]);

    try {
      await axios
        .post(
          `https://${PYYPL_API_DOMAIN}/webapp/fetch-chat-bot-message-completion`,
          {
            chatBotMessageSessionId,
            ...(userData?.userId && { chatBotMessageUserId: userData?.userId }),
            chatBotMessagePrompt: input,
          },
        )
        .then((response) => {
          const { data } = response || {};
          if (response.data.error) {
            setMessages([...chat, {
              role: 'bot',
              content: 'Sorry i can not help you with that, kindly request for immediate assistance below 👇',
              timestamp: new Date().toLocaleTimeString(),
            }]);
          } else {
            setMessages([...chat, {
              id: data.chatBotMessageId,
              role: 'bot',
              content: data.chatBotMessageCompletion,
              timestamp: new Date().toLocaleTimeString(),
            }]);
          }
        })
        .catch((error) => {
          console.log(error);
          setMessages([...chat, {
            role: 'bot',
            content: 'Sorry i can not help you with that, kindly request for immediate assistance below 👇',
            timestamp: new Date().toLocaleTimeString(),
          }]);
        });
    } catch (error) {
      console.log((error as Error).message);
      setMessages([...chat, {
        role: 'bot',
        content: 'Sorry i can not help you with that, kindly request for immediate assistance below 👇',
        timestamp: new Date().toLocaleTimeString(),
      }]);
    }
    setLoading(false);
  };

  const handleKeyDown = async (event: any) => {
    if (input !== '' && (event.key === 'Enter' || event.keyCode === 13) && !loading) {
      event.preventDefault();
      await handleSubmit(event);
    }
  };

  return (
    <>
      <Header
        title="Looking for<br>any assistance 👋"
        subtitle="What kind of help do you need today?"
      />
      <Box component="span" m={1}>
        <Container maxWidth="sm" style={{ padding: 0 }}>
          <Card className={classes.box} style={{ padding: 0 }}>
            <CardContent>
              <Grid container spacing={1} justifyContent="center">
                <Grid
                  container
                  spacing={1}
                  className={classes.details}
                  style={{ height: '300px', overflow: 'scroll' }}
                >
                  {messages.length > 0
                    && messages.map((data, index) => (
                      <Grid key={index} item xs={12} style={{ padding: '5px' }}>
                        {
                          data.role === 'bot' && (
                          <div>
                            <div style={{
                              fontSize: '14px',
                              padding: '10px 0',
                              display: 'flex',
                            }}
                            >
                              <Avatar
                                alt="Bot Pic"
                                src="https://webapp.dev.pyypl.io/favicon.ico"
                                className={classes.avatar}
                              />
                              <div style={{ paddingTop: '7px' }}>{data.content}</div>
                            </div>
                            {
                              data.id && (
                              <div>
                                <Rating
                                  onClick={(rate) => setChatMessageCompletionRating({ id: data.id as string, rate })}
                                  size={20}
                                  transition
                                  allowFraction
                                  showTooltip
                                  tooltipStyle={{ fontSize: '12px' }}
                                  fillColorArray={[
                                    '#f17a45',
                                    '#f17a45',
                                    '#f19745',
                                    '#f19745',
                                    '#f1a545',
                                    '#f1a545',
                                    '#f1b345',
                                    '#f1b345',
                                    '#f1d045',
                                    '#f1d045',
                                  ]}
                                  tooltipArray={[
                                    'Terrible',
                                    'Terrible+',
                                    'Bad',
                                    'Bad+',
                                    'Average',
                                    'Average+',
                                    'Great',
                                    'Great+',
                                    'Awesome',
                                    'Awesome+',
                                  ]}
                                />
                              </div>
                              )
                            }
                            <div style={{ color: '#6c757d', fontSize: '12px' }}>
                              {data.timestamp}
                            </div>
                          </div>
                          )
                        }
                        {
                          data.role === 'user' && (
                            <div style={{
                              float: 'right',
                            }}
                            >
                              <div style={{
                                fontSize: '14px',
                                padding: '10px 0',
                                display: 'flex',
                                float: 'right',
                              }}
                              >
                                <div style={{
                                  paddingTop: '7px',
                                  textAlign: 'right',
                                }}
                                >
                                  {data.content}
                                </div>
                                <Avatar
                                  alt="User Pic"
                                  src={userData?.userAvatar || 'https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg'}
                                  className={classes.avatar}
                                />
                              </div>
                              <div style={{
                                color: '#6c757d',
                                fontSize: '12px',
                                textAlign: 'right',
                              }}
                              >
                                {data.timestamp}
                              </div>
                            </div>
                          )
                        }
                      </Grid>
                    ))}
                  <div ref={messagesEndRef} />
                </Grid>
              </Grid>
              { ReactNativeWebView && (
                <Grid container spacing={1} justifyContent="center">
                  <Grid
                    container
                    spacing={1}
                  >
                    <div className={classes.chatTopicsScrollBar}>
                      { TOPICS_DEFINITIONS.map((topic, index) => (
                        <Button key={index} onClick={() => handleTopicSubmission(topic.message)}>
                          <div className={`${classes.chatTopics} ${classes.fontSmall}`}>{topic.title}</div>
                        </Button>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={1} justifyContent="center">
                <Grid container spacing={1} className={classes.details}>
                  <Grid item xs={12}>
                    <Input
                      required
                      name="input"
                      placeholder="What can i help with..."
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      value={input}
                      error={!input}
                      style={{ width: '80%', fontSize: '14px' }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      startIcon={loading ? <CircularProgress size={14} /> : <Send />}
                      onClick={handleSubmit}
                      className={loading ? classes.buttonLoadingOutline : classes.buttonReadyOutline}
                      disabled={loading || !input}
                      style={{
                        backgroundColor: 'transparent', boxShadow: 'none', float: 'right', minWidth: '35px', padding: '6px 0px',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Grid container spacing={1} justifyContent="center">
        <Grid container spacing={1} className={classes.details}>
          <Grid item xs={12} style={{ padding: '0 15px 15px 15px' }}>
            { showAgentSupport && (
            <div style={{ color: 'rgb(108, 117, 125)', fontSize: '12px', textAlign: 'center' }}>
              Looking for immediate assistance? Click
              <Button
                variant="text"
                style={{
                  backgroundColor: 'transparent',
                  textTransform: 'none',
                  minWidth: 'auto',
                  fontSize: '12px',
                  padding: '0px 4px',
                  margin: '0px 0 1px 0',
                }}
                className={classes.buttonReadyOutline}
                onClick={() => redirectToSupportAgent()}
              >
                here
              </Button>
            </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <CompletionCorrectionModal
        setShowModal={setShowModal}
        showModal={showModal}
        handleSubmitCompletionCorrection={handleSubmitCompletionCorrection}
      />
    </>
  );
};

export default ChatBot;
