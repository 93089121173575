import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import securedSessionStorage from '../lib/securedSessionStorage';
import { KycRequirementKey } from '../types';
import { KycStore } from './types';

export const useKycStore = create<KycStore>()(
  devtools(
    persist(
      (set, get) => ({
        kycStateKeys: [],
        kycIDVSession: null,
        setKycStateKeys: (kycStateKeys) => set({ kycStateKeys }),
        currentKycStateKey: null,
        nextKycStateKey: null,
        previousKycStateKey: null,
        setCurrentKycStateKey: (currentKycStateKey) => {
          const { kycStateKeys } = get();
          set({
            currentKycStateKey,
            nextKycStateKey: kycStateKeys[kycStateKeys.indexOf(currentKycStateKey as KycRequirementKey) + 1] || null,
            previousKycStateKey: kycStateKeys[kycStateKeys.indexOf(currentKycStateKey as KycRequirementKey) - 1] || null,
          });
        },
        setKycIDVSession: (kycIDVSession) => set({ kycIDVSession }),
      }),
      {
        name: 'kyc-store',
        storage: createJSONStorage(() => securedSessionStorage),
      },
    ),
  ),
);
