import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import theme from '../../../assets/styles/theme';
import PyyplHeader from '../../../components/PyyplHeader';
import { mapTextFieldErrorProps } from '../../../lib/formHelpers';
import { createAddressInfo } from '../../../lib/graphql';
import { useAuthStore } from '../../../store/auth.store';

const addressInfoFormSchema = yup
  .object()
  .shape({
    address1: yup.string().required(),
    address2: yup.string(),
    city: yup.string().required(),
    country: yup.string().required(),
    phoneNumber: yup.string().required(),
    zipCode: yup.string().required(),
  })
  .required();

export type AddressInfoFormData = Omit<
  yup.InferType<typeof addressInfoFormSchema>,
  'address2'
> & { address2: string };

const defaultAddressInfoFieldsDefinition = {
  address1: '',
  address2: '',
  city: '',
  country: '',
  phoneNumber: '',
  zipCode: '',
};

type AddressInfoProps = {
  onError: (error?: Error | Record<string, any>) => void;
  onSuccess: (response?: Record<string, any>) => void;
};

const AddressInfo = (props: AddressInfoProps) => {
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const { onError = () => {}, onSuccess = () => {} } = props;
  const authStore = useAuthStore();
  const { user } = authStore;

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    defaultValues: {
      ...defaultAddressInfoFieldsDefinition,
      country: new Intl.DisplayNames(['en'], { type: 'region' }).of(
        parsePhoneNumber(user?.phoneNumber || '')?.country || ('AE' as string),
      ),
      phoneNumber: user?.phoneNumber,
    },
    resolver: yupResolver(addressInfoFormSchema),
    mode: 'onChange',
  });
  const onSubmit: SubmitHandler<AddressInfoFormData> = async (data) => {
    try {
      const userId = authStore.user?.userId as string;
      const { phoneNumber, ...addressInfoInput } = data;
      await createAddressInfo(userId, {
        ...addressInfoInput,
        country: parsePhoneNumber(user?.phoneNumber || '')?.country || 'AE',
      });
      onSuccess({ addressInfoSubmitted: true });
    } catch (error) {
      console.log('error storeAddressInfo', error);
      onError(error as Error);
    }
  };

  return (
    <>
      <PyyplHeader
        tagline="GETTING STARTED"
        title="Address info"
        // eslint-disable-next-line max-len
        subtitle="To get started, please provide your address information by following the instructions below. This information will help us ensure that you have access to the features and services available in your area."
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box component="span" m={1}>
          <Container maxWidth="sm" style={{ margin: '30px auto' }}>
            <Card className={classes.box}>
              <CardContent>
                <Grid container spacing={1} justifyContent="center">
                  <Grid container spacing={1} className={classes.details}>
                    <Grid item xs={12}>
                      <Controller
                        name="address1"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth>
                            <TextField
                              {...field}
                              {...mapTextFieldErrorProps(fieldState)}
                              disabled={isSubmitting}
                              required
                              label="Address"
                              placeholder="Enter your address"
                              style={{ width: '95%' }}
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className={classes.details}>
                    <Grid item xs={12}>
                      <Controller
                        name="address2"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            {...mapTextFieldErrorProps(fieldState)}
                            disabled={isSubmitting}
                            label="Address 2"
                            placeholder="Enter your address"
                            style={{ width: '95%' }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className={classes.details}>
                    <Grid item xs={12}>
                      <Controller
                        name="zipCode"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            {...mapTextFieldErrorProps(fieldState)}
                            disabled={isSubmitting}
                            label="Zipcode"
                            required
                            placeholder="Enter zipcode"
                            style={{ width: '95%' }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className={classes.details}>
                    <Grid item xs={12}>
                      <Controller
                        name="city"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            {...mapTextFieldErrorProps(fieldState)}
                            disabled={isSubmitting}
                            label="City"
                            required
                            placeholder="Enter city"
                            style={{ width: '95%' }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className={classes.details}>
                    <Grid item xs={12}>
                      <Controller
                        name="country"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            {...mapTextFieldErrorProps(fieldState)}
                            label="Country"
                            disabled
                            placeholder="Enter country"
                            style={{ width: '95%' }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.details}>
                  <Grid item xs={12}>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          {...mapTextFieldErrorProps(fieldState)}
                          label="Phone Number"
                          disabled
                          placeholder="Enter phone number"
                          style={{ width: '95%' }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>

          <Container maxWidth="sm">
            <Grid container spacing={1} justifyContent="center">
              <Grid
                container
                spacing={1}
                className={classes.details}
                justifyContent="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={isSubmitting ? <CircularProgress size={14} /> : ''}
                  className={
                    !isValid || isSubmitting
                      ? classes.buttonLoading
                      : classes.buttonReady
                  }
                  disabled={!isValid || isSubmitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </form>
    </>
  );
};
export default AddressInfo;
