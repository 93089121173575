import { CircularProgress } from '@material-ui/core';
import React from 'react';

type LoaderProps = {
  loading: boolean
}

const PyyplLoader = (props: LoaderProps) => {
  const { loading } = props;
  if (loading) return (<CircularProgress size={35} />);
  return null;
};

export default PyyplLoader;
