import { GeoLocationData, MayBe } from '../types';

export const getGeoLocationData = async (): Promise<MayBe<GeoLocationData>> => {
  try {
    const response = await fetch('https://get.geojs.io/v1/ip/geo.js');
    const data = await response.text();
    const jsonStr = data.replace(/^geoip\(/, '').replace(/\);?\s*$/, '');
    return JSON.parse(jsonStr);
  } catch (error) {
    console.log('getGeoLocationDetails error:', (error as Error).message);
    return null;
  }
};

export const hexColorOpacity = (hexColor: string, decimalOpacity: number): string => {
  const hexOpacity = Math.floor(decimalOpacity * 255).toString(16).padStart(2, '0');
  return `${hexColor}${hexOpacity}`;
};

export const isValidEmail = (value: string) => {
  if (!value || value === '') {
    return true;
  }
  // A more strict email validation regex
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(value);
};

export const determineDenom = (currency?: string) => {
  if (!currency) return 100;

  const subunits: Record<string, string[]> = {
    100000000: ['BTC'], // 1 BTC === 100,000,000 Satoshis
    1000: ['BHD', 'IQD', 'KWD', 'LYD', 'OMR', 'TND'], // 1000 subunits
    10: ['VND'], // 10 subunits
    5: ['MRU', 'MGA'], // 5 subunits
    1: ['UGX', 'PTS'],
    // Ugandan (new) shilling, officially divided into cents until 2013, the shilling now has no subdivision
    // PTS - Pyypl Loyalty Points
  };
  type SubUnits = keyof typeof subunits;
  const denom = Number(
    Object.keys(subunits)
      .find((key) => subunits[key as SubUnits].includes(currency || '')),
  );
  return denom || 100;
};
/**
 * Formats a given numeric value as a currency or decimal string according to the provided options.
 *
 * @param {Object} options - Configuration object for the formatting function.
 * @param {number | null} options.value - The numeric value to be formatted.
 * @param {string} [options.locale = 'en-EN'] - The locale to be used for formatting (e.g., 'en-EN' or 'fr-FR'). Default is 'en-EN'.
 * @param {string} [options.currency] - The ISO 4217 currency code (e.g., 'USD' or 'EUR'). If not provided, the formatted value will not include a currency symbol.
 * @param {boolean} [options.cents = true] - Whether to display cents (decimals) in the formatted value. Default is true.
 * @returns {string} The formatted currency or decimal string.
 */
export const formatCurrency = ({
  value,
  locale = 'en-EN',
  currency,
  cents = true,
}: {
  value: number | null;
  locale?: string;
  currency?: string;
  cents?: boolean;
}) => {
  const currencyValue = cents ? (value || 0) / determineDenom(currency) : (value || 0);

  return new Intl.NumberFormat(locale, {
    style: currency ? 'currency' : 'decimal',
    currency,
  }).format(currencyValue);
};
