import crypto, { createECDH, createHash } from 'crypto';
import { ec as Ec } from 'elliptic';

const {
  REACT_APP_PYYPL_ENCRYPTION_HASH: PYYPL_ENCRYPTION_HASH,
} = process.env;

const encryptionHashedKey = crypto
  .pbkdf2Sync(PYYPL_ENCRYPTION_HASH!, PYYPL_ENCRYPTION_HASH!, 1, 32, 'sha256');

export const encryptValue = (value: string): string => {
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv('aes-256-cbc', encryptionHashedKey, iv);
  let encrypted = cipher.update(value, 'utf8', 'hex');
  encrypted += cipher.final('hex');
  return `${iv.toString('hex')}:${encrypted}`;
};

export const decryptValue = (value: string): string => {
  const parts = value.split(':');
  const iv = Buffer.from(parts.shift() as string, 'hex');
  const decipher = crypto.createDecipheriv('aes-256-cbc', encryptionHashedKey, iv);
  let decrypted = decipher.update(parts.join(':'), 'hex', 'utf8');
  decrypted += decipher.final('utf8');
  return decrypted;
};

export type KeyPairs = {
  publicKey: Buffer;
  privateKey: Buffer;
};

export const generateKeys = (): KeyPairs => {
  const ecdh = createECDH('secp256k1');
  ecdh.generateKeys();
  return {
    publicKey: ecdh.getPublicKey(),
    privateKey: ecdh.getPrivateKey(),
  };
};

export const signText = (text: string, privateKey: Buffer): Buffer => {
  const ell = new Ec('secp256k1');
  const hashedText = createHash('sha256').update(Buffer.from(text)).digest();
  return Buffer.from(
    ell.sign(hashedText, Buffer.from(privateKey), { canonical: true }).toDER(),
  );
};

export const verifySignature = (
  text: string,
  signature: Buffer,
  publicKey: Buffer,
): boolean => {
  const ell = new Ec('secp256k1');
  const hashedText = createHash('sha256').update(Buffer.from(text)).digest();
  return ell.verify(hashedText, Buffer.from(signature), Buffer.from(publicKey));
};

export const generateHash = (text: string): string =>
  createHash('sha256').update(Buffer.from(text)).digest().toString('hex');

export type GeneratedKeyPairs = {
  publicKey: string;
  privateKey: string;
  textSignature: string;
  signature: Buffer;
};

export const generatePrivatePublicKey = (): GeneratedKeyPairs => {
  const generatedBufferKeys = generateKeys();

  const stringPublicKey = Buffer.from(generatedBufferKeys.publicKey).toString(
    'hex',
  );
  const stringPrivateKey = Buffer.from(generatedBufferKeys.privateKey).toString(
    'hex',
  );
  const textSignature = Math.random().toString();

  const signature = signText(
    textSignature,
    // @ts-ignore
    Buffer.from(generatedBufferKeys.privateKey, 'hex'),
  );
  const verified = verifySignature(
    textSignature,
    signature,
    // @ts-ignore
    Buffer.from(generatedBufferKeys.publicKey, 'hex'),
  );
  if (!verified) {
    throw new Error('not able to verify keys');
  }
  return {
    publicKey: stringPublicKey,
    privateKey: stringPrivateKey,
    textSignature,
    signature,
  };
};
