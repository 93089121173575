import { parsePhoneNumber } from 'libphonenumber-js';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { GeneratedKeyPairs } from '../lib/crypto';
import { getUser, listAccounts } from '../lib/graphql';
import securedSessionStorage from '../lib/securedSessionStorage';
import { getGeoLocationData } from '../lib/utils';
import { AuthStore } from './types';

export const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        deviceKeys: null,
        geoLocationData: null,
        userAccounts: [],
        defaultUserAccount: null,
        onboardingRequestDeepLink: null,
        setOnboardingRequestDeepLink: (onboardingRequestDeepLink: string) => set({ onboardingRequestDeepLink }),
        initUserAccountsData: async () => {
          const userAccounts = await listAccounts();
          const defaultUserAccount = userAccounts.find(
            (userAccount) => userAccount?.name === 'default',
          );
          return set({ userAccounts, defaultUserAccount });
        },
        initUserData: async (userId: string) => {
          const user = await getUser(userId);
          if (user && !user.assignedOperatingCountry) {
            user.assignedOperatingCountry = parsePhoneNumber(
              user?.phoneNumber!,
            ).country!;
          }
          return set({ user });
        },
        initGeoLocationData: async () => {
          const geoLocationData = await getGeoLocationData();
          return set({ geoLocationData });
        },
        setDeviceKeys: (deviceKeys: GeneratedKeyPairs) => set({ deviceKeys }),
      }),
      {
        name: 'auth-store',
        storage: createJSONStorage(() => securedSessionStorage),
      },
    ),
  ),
);
