import { CognitoUser } from '@aws-amplify/auth';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import jsSha3 from 'js-sha3';
import crypto from 'crypto';

export const signIn = async (
  username: string,
  password: string | undefined,
  clientMetadata: Record<string, any> | undefined = undefined,
): Promise<any> => Auth.signIn(username, password, clientMetadata);

export const sendCustomChallengeAnswer = async (
  user: CognitoUser,
  answer: string,
  meta: Record<string, any>,
): Promise<any> => Auth.sendCustomChallengeAnswer(user, answer, meta);

const formatToUUID = (string: string) => {
  const arr = string.split('');
  return (
    `${arr.splice(0, 8).join('')}-`
    + `${arr.splice(0, 4).join('')}-`
    + `${arr.splice(0, 4).join('')}-`
    + `${arr.splice(0, 4).join('')}-`
    + `${arr.join('')}`
  );
};

export const generateUsername = (publicKey: string): string => formatToUUID(jsSha3.keccak256(publicKey.slice(2)).slice(32));

export const generatePassword = (length: number): string => crypto.randomBytes(Math.ceil(length / 2)).toString('hex').slice(0, length);

export const signUp = async (publicKey: string, data: Record<string, any>): Promise<ISignUpResult> => {
  const username = generateUsername(publicKey);
  const password = generatePassword(9);
  return Auth.signUp({ username, password, clientMetadata: data });
};

export const getCurrentSessionJwtToken = async (): Promise<string | null> => {
  try {
    const session = await Auth.currentSession();
    return session?.getIdToken()?.getJwtToken() || null;
  } catch (error) {
    console.log('getIdToken', error);
    return null;
  }
};
