import { makeStyles } from '@material-ui/core';
import { CountryCode } from 'libphonenumber-js';
import { isString } from 'lodash';
import React from 'react';
import theme from '../assets/styles/theme';

const URL = 'http://purecatamphetamine.github.io/country-flag-icons/3x2/{XX}.svg';

export type CountryFlagProps = {
  countryCode: CountryCode;
  style?: React.CSSProperties;
};

const CountryFlag = ({ countryCode, style }: CountryFlagProps) => {
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  if (!isString(countryCode)) return null;
  return (
    <div
      style={({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        ...style,
      })}
    >
      <img
        className={classes.flagIcon}
        alt={countryCode}
        src={URL.replace('{XX}', countryCode).replace(
          '{xx}',
          countryCode.toLowerCase(),
        )}
      />
    </div>
  );
};

export default CountryFlag;
