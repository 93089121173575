import AddressIcon from '../../assets/images/kyc/address.png';
import IdIcon from '../../assets/images/kyc/id.png';
import SelfieIcon from '../../assets/images/kyc/selfie.png';
import {
  DueDiligenceDocumentConfig,
  DueDiligenceDocuments,
  KycRequirementConfig,
  KycRequirementKey,
  KycRequirementType,
} from '../../types';

export const KycRequirementsConfig: Record<
  KycRequirementType,
  KycRequirementConfig
> = {
  GOVERNMENT_IDENTIFICATION_DOCUMENT: {
    kycRequirementKey: KycRequirementKey.ID,
    kycRequirementType: KycRequirementType.GOVERNMENT_IDENTIFICATION_DOCUMENT,
    kycRequirementIcon: <img src={IdIcon} width="32" height="32" alt="id" />,
    kycRequirementTitle: 'Government Issued Document',
    kycRequirementDescription: 'Any government generated document',
    kycRequirementOrder: 4,
  },
  SELFIE_PHOTO: {
    kycRequirementKey: KycRequirementKey.ID,
    kycRequirementType: KycRequirementType.SELFIE_PHOTO,
    kycRequirementIcon: (
      <img src={SelfieIcon} width="32" height="32" alt="selfie" />
    ),
    kycRequirementTitle: 'Selfie',
    kycRequirementDescription: 'Your selfie image',
    kycRequirementOrder: 4,
  },
  ADDRESS_INFO: {
    kycRequirementKey: KycRequirementKey.ADDRESS_INFO,
    kycRequirementType: KycRequirementType.ADDRESS_INFO,
    kycRequirementIcon: (
      <img src={AddressIcon} width="32" height="32" alt="address" />
    ),
    kycRequirementTitle: 'Address Information',
    kycRequirementDescription: 'Your address information',
    kycRequirementOrder: 1,
  },
  SOURCE_OF_FUNDS: {
    kycRequirementKey: KycRequirementKey.SOURCE_OF_FUNDS,
    kycRequirementType: KycRequirementType.SOURCE_OF_FUNDS,
    kycRequirementIcon: (
      <img src={SelfieIcon} width="32" height="32" alt="source-of-funds" />
    ),
    kycRequirementTitle: 'Source of funds',
    kycRequirementDescription: 'Your source of funds information',
    kycRequirementOrder: 2,
  },
};

export const DocumentsConfig: Record<
  DueDiligenceDocuments,
  DueDiligenceDocumentConfig
> = {
  [DueDiligenceDocuments.PASSPORT]: {
    documentType: DueDiligenceDocuments.PASSPORT,
    documentIcon: <img src={IdIcon} width="32" height="32" alt="passport" />,
    documentTitle: 'Passport',
    documentDescription: 'Use your Passport to verify your account',
  },
  [DueDiligenceDocuments.IDCARD]: {
    documentType: DueDiligenceDocuments.IDCARD,
    documentIcon: <img src={IdIcon} width="32" height="32" alt="id" />,
    documentTitle: 'National ID',
    documentDescription: 'Use your National ID to verify your account',
  },
  [DueDiligenceDocuments.RESIDENCY_CARD]: {
    documentType: DueDiligenceDocuments.RESIDENCY_CARD,
    documentIcon: (
      <img src={IdIcon} width="32" height="32" alt="residency-card" />
    ),
    documentTitle: 'Residency Card',
    documentDescription: 'Use your residency card to verify account',
  },
  [DueDiligenceDocuments.DRIVERS_LICENSE]: {
    documentType: DueDiligenceDocuments.DRIVERS_LICENSE,
    documentIcon: (
      <img src={IdIcon} width="32" height="32" alt="drivers-license" />
    ),
    documentTitle: 'Driving License',
    documentDescription: 'Use your Driving Licence to verify your account',
  },
  [DueDiligenceDocuments.TWO_PHOTOS]: {
    documentType: DueDiligenceDocuments.TWO_PHOTOS,
    documentIcon: (
      <img src={IdIcon} width="32" height="32" alt="drivers-license" />
    ),
    documentTitle: 'Two photos',
    documentDescription: 'Use your two photos to verify your account',
  },
  [DueDiligenceDocuments.OTHER]: {
    documentType: DueDiligenceDocuments.OTHER,
    documentIcon: (
      <img src={IdIcon} width="32" height="32" alt="drivers-license" />
    ),
    documentTitle: 'Other',
    documentDescription: 'Use your other document to verify your account',
  },
};
