import {
  Box, makeStyles, Modal, Typography,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import React, { memo, useEffect, useState } from 'react';
import theme, { COLORS } from '../assets/styles/theme';
import securedSessionStorage from '../lib/securedSessionStorage';

type Action = {
  Element: (props: any) => React.ReactElement<any>;
  callback: () => void;
};

export type PyyplModalConfig = {
  title?: string;
  header?: string;
  body?: string;
  variant?: 'success' | 'info' | 'warning' | 'error';
  closeEnabled?: boolean;
  actions?: Action[];
  Content?: React.ReactElement<any>;
};

export type PyyplModalProps = {
  config: PyyplModalConfig;
};

const PyyplModal = (props: PyyplModalProps) => {
  const { config } = props;
  const {
    title, header, body, actions, variant, Content, closeEnabled,
  } = config || {};
  const [showModal, setShowModal] = useState(false);

  const useStyles = makeStyles(theme);
  const classes = useStyles();

  const dismissModal = () => {
    securedSessionStorage.clear();
    setShowModal(false);
  };

  useEffect(() => {
    if (!showModal && config !== null) {
      setShowModal(true);
    }
  }, [config]);

  return (
    <Modal
      open={showModal}
      onClose={(_event, reason) => {
        if (!['backdropClick', 'escapeKeyDown'].includes(reason)) {
          dismissModal();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.pyyplModal}
      style={{ paddingTop: 28 }}
    >
      <Box className={classes.pyyplModalBox}>
        <div
          style={{ textAlign: 'right', padding: '0 20px', cursor: 'pointer' }}
        >
          {(closeEnabled || variant === 'error') && (
            <CloseOutlined onClick={() => dismissModal()} />
          )}
        </div>
        <div style={{ maxWidth: '460px', margin: 'auto', padding: '0 30px' }}>
          {header && (
            <Typography
              id="modal-modal-header"
              variant="h6"
              component="h2"
              className={classes.pyyplModalHeader}
            >
              {header}
            </Typography>
          )}

          {title && (
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={classes.pyyplModalTitle}
              style={{ color: variant ? COLORS[variant] : COLORS.secondary }}
            >
              {title}
            </Typography>
          )}

          {body && (
            <Typography
              id="modal-modal-description"
              className={classes.pyyplModalBody}
            >
              {body}
            </Typography>
          )}

          {!!Content && (
            <div className={classes.pyyplModalContent}>{Content}</div>
          )}

          {actions && (
            <div className={classes.pyyplModalActions}>
              {actions.map(({ Element, callback }) => (
                <Element
                  key={`pyyplModal_${Date.now()}`}
                  className={classes.buttonReady}
                  onClick={() => {
                    setShowModal(false);
                    callback && callback();
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default memo(PyyplModal);
